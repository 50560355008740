<template>
  <div class="steps">

    <div v-for="(item,index) in data" :key='item.title' class="step-item" :class="routeIndex-1==index?'active-step-item':''">
      <div>
        <span class="el-step__icon">{{index+1}}</span>
        <span class="el-step-title">{{item.title}}</span>
      </div>
      <div class="el-step-line" v-if="index+1!=data.length"></div>
    </div>
  </div>
</template>

<script>
export default {
    props:['data','routeIndex'],
  data() {
    return {
      active: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  .step-item {
    display: flex;
    align-items: center;
    .el-step-line {
      height: 1px;
      border-color: inherit;
      background-color: #c0c4cc;
      width:192px;
      margin: 0 12px;
    }
    .el-step-title {
      font-size: 16px;
      color: #c0c8da;
      margin-left: 8px;
    }
    .el-steps {
      width: 100%;
    }
    .el-step__icon {
      position: relative;
      z-index: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      font-size: 16px;
      box-sizing: border-box;
      background: transparent;
      transition: 0.15s ease-out;
      border-radius: 50%;
      border: 2px solid #c0c8da;
      border-color: inherit;
      color: #c0c8da;
    }
  }
  .step-item:last-child {
    .el-step-line {
      display: none;
    }
  }
  .active-step-item{
      .el-step__icon{
        color: #374567;
        border-color: #00A0E9;
        background: #00A0E9;
        color: #fff;
      }
      .el-step-title{
          color: #374567;
      }
  }
}
</style>
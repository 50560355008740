<template>
  <el-input
    type="password"
    :show-password="true"
    :placeholder="placeholder"
    size="small"
    :clearable="true"
    v-model="passwordValue"
  >
    <!-- <i slot="prefix" class="el-input__icon el-icon-s-goods"></i> -->
    <img
      src="@/assets/img/password_icon.png"
      class="password-icon"
      slot="prefix"
      alt=""
      v-if="isIcon"
    />
  </el-input>
</template>

<script>
export default {
  props: {
    isIcon: {
      type: Boolean,
      default: true,
    },
    placeholder:{
      type:String,
      default:'密码'
    }
  },
  data() {
    return {
      passwordValue: "",
    };
  },
  watch: {
    passwordValue(val) {
      this.$emit("getPassword", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.password-icon {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  padding-left: 5px;
}
.el-input {
  img {
    margin-top: -3px;
  }
}
</style>
<template>
  <div>
    <SliderCheck
      ref="slider"
      :successFun="handleSuccessFun"
      :errorFun="handleErrorFun"
    ></SliderCheck>
  </div>
</template>

<script>
import SliderCheck from "@/components/slider_check.vue";

export default {
  components: { SliderCheck },
  props: ["phone"],
  methods: {
    // 滑块验证成功回调
    handleSuccessFun() {
      this.$emit("phoneVerify");
    },
    // 滑块验证失败回调
    handleErrorFun() {},
    reset() {
      this.$refs.slider.reset();
    },
  },
};
</script>

<style lang="less" scoped>
</style>